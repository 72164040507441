import { object, ref, string } from "yup";

const passwordSchema = string()
    .min(8, "COMMON.PWD_2_Short")
    .required("COMMON.Required_Input")
    .matches(/[a-z]+/, "COMMON.At_least_1_lowercase")
    .matches(/[A-Z]+/, "COMMON.At_least_1_uppercase")
    .matches(/[@$!%*#?&^.<>:;+=]+/, "COMMON.At_least_1_special")
    .matches(/\d+/, "COMMON.At_least_1_number");

const registerConfirmPasswordSchema = string()
    .oneOf([ref("password"), undefined], "COMMON.PWD_NotMatch")
    .required("COMMON.Required_Input");

const confirmPasswordSchema = string()
    .oneOf([ref("newPassword"), undefined], "COMMON.PWD_NotMatch")
    .required("COMMON.Required_Input");

export const loginSchema = () => {
    const schema = {
        email: string()
            .required("COMMON.Required_Input")
            .test("is-email", "COMMON.Email_Error", (value) => {
                if (value) {
                    return value.includes("@") ? string().email("COMMON.Email_Error").isValidSync(value) : true;
                }
                return true;
            }),
        password: passwordSchema,
    };
    return object().shape(schema);
};

export const enterpriseLoginSchema = () => {
    const schema = {
        //org_serial_number: string().required("COMMON.Required_Input"),
        //account: string().required("COMMON.Required_Input"),
        id: string().required("COMMON.Required_Input"),
        password: string().required("COMMON.Required_Input"),
    };
    return object().shape(schema);
};

export const registerSchema = (isWaitConfirmCode: boolean) => {
    const schema = {
        //username: string().required("COMMON.Required_Input"),
        ...(!isWaitConfirmCode
            ? {
                  email: string().email("COMMON.Email_Error").required("COMMON.Required_Input"),
                  password: passwordSchema,
                  okPassword: registerConfirmPasswordSchema,
              }
            : { registerCode: string().required("COMMON.Required_Input") }),
    };

    return object().shape(schema);
};

export const forgotPwdSchema = (isSentCode: boolean) => {
    const schema = {
        email: string().email("COMMON.Email_Error").required("COMMON.Required_Input"),
        ...(isSentCode && {
            newPassword: passwordSchema,
            okPassword: confirmPasswordSchema,
            confirmationCode: string().required("COMMON.Required_Input"),
        }),
    };
    return object().shape(schema);
};

export const updatePasswordSchema = () => {
    const schema = {
        //oldPassword: passwordSchema,
        newPassword: passwordSchema,
        okPassword: confirmPasswordSchema,
        confirmationCode: string().required("COMMON.Required_Input"),
    };
    return object().shape(schema);
};
